<template>
  <v-container
    id="industries-list"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="8"
        md="8"
      >
        <base-material-card color="info">
          <template v-slot:heading>
            <div class="text-h3 font-weight-light">
              Available
            </div>

            <div class="text-text-subtitle-1 font-weight-light">
              (in List)
            </div>
          </template>
          <v-card
            class="mx-auto"
            outlined
          >
            <industry-list
              :key="listKey"
              @editItem="editItem"
            />
          </v-card>
        </base-material-card>
      </v-col>

      <v-col
        cols="4"
        md="4"
      >
        <base-material-card>
          <template v-slot:heading>
            <div class="text-h3 font-weight-light">
              Add New
            </div>

            <div class="text-text-subtitle-1 font-weight-light">
              Add new Industry item
            </div>
          </template>

          <v-form>
            <v-container class="py-0">
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-text-field
                    v-model="industryObject.name"
                    class="purple-input"
                    label="Industry Name"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="12"
                >
                  <v-file-input
                    v-model="industryObject.profile_photo"
                    :rules="[
                      value => !value || value.size < 2000000 || 'Image size should be less than 2 MB!',
                    ]"
                    accept="image/png, image/jpeg, image/bmp"
                    placeholder="Pick an Image"
                    prepend-icon="mdi-camera"
                    label="Industry Image"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="12"
                >
                  <v-checkbox
                    v-model="industryObject.is_featured"
                    label="Featured"
                  />
                </v-col>

                <v-col
                  cols="12"
                  class="text-right"
                >
                  <v-btn
                    color="success"
                    class="mr-0"
                    @click="isEdit ? updateIndustryAction() : createIndustryAction()"
                  >
                    {{ isEdit ? 'Update' : 'Create' }} Industry
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-alert
                    v-if="alert.show"
                    border="left"
                    dense
                    text
                    :type="alert.type"
                  >
                    {{ alert.text }}
                  </v-alert>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { createIndustry, updateIndustry } from '@/services/admin'
  import industryList from '@/components/industryList'
  const toSlug = function (str) {
    str = str.replace(/^\s+|\s+$/g, '') // trim
    str = str.toLowerCase()

    // remove accents, swap ñ for n, etc
    var from = 'àáäâèéëêìíïîòóöôùúüûñçěščřžýúůďťň·/_,:;'
    var to = 'aaaaeeeeiiiioooouuuuncescrzyuudtn------'

    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
    }

    str = str.replace('.', '-') // replace a dot by a dash
      .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by a dash
      .replace(/-+/g, '-') // collapse dashes
      .replace(/\//g, '') // collapse all forward-slashes

    return str
  }
  export default {
    components: { industryList },
    data () {
      return {
        industryObject: {

        },
        alert: {
          show: false,
          text: '',
          type: 'success',
        },
        isEdit: false,
        itemId: null,
        listKey: 'abc',
      }
    },
    computed: {
      industrySlug () {
        return toSlug(this.industryObject.name)
      },
    },
    methods: {
      createIndustryAction () {
        if (!this.industryObject.name) {
          this.alert.show = true
          this.alert.text = 'Please fill all required fields.'
          this.alert.type = 'error'
          return
        }
        this.industryObject.slug = this.industrySlug
        console.log('1', this.industryObject)
        createIndustry(this.industryObject).then(res => {
          this.alert.show = true
          this.alert.text = 'Industry Created.'
          this.alert.type = 'success'
        })
      },
      updateIndustryAction () {
        if (!this.industryObject.name) {
          this.alert.show = true
          this.alert.text = 'Please fill all required fields.'
          this.alert.type = 'error'
          return
        }
        this.industryObject.slug = this.industrySlug
        updateIndustry(this.itemId, this.industryObject).then(res => {
          this.alert.show = true
          this.alert.text = 'Industry Updated.'
          this.alert.type = 'success'
          this.isEdit = false
          this.industryObject = {}
          this.listKey = new Date()
        })
      },
      editItem (items) {
        const data = JSON.parse(items)
        this.itemId = data.id
        this.industryObject = {
          name: data.name,
          is_featured: data.is_featured,
          profile_photo: data.profile_photo,
        }
        this.isEdit = true
        this.alert.show = false
      },
      getReadableDate (date) {
        const d = new Date(date)
        return d.toUTCString()
      },
    },
  }
</script>
